<template>
  <div class="contact">
    <PozzyContact/>
  </div>
</template>

<script>
// @ is an alias to /src
import PozzyContact from '@/components/Contact.vue'

export default {
  name: 'Contact',
  components: {
    PozzyContact
  }
}
</script>
