<template>
  <v-container>
    <v-row class="text-center">
      <v-col
          class="mb-5"
          cols="12"
      >
        <v-divider></v-divider>
        <v-container fluid>
          <v-card
              class="mx-auto my-12"
              max-width="60%"
          >

            <v-card-text>
              <div>
                <h1 title="Pozzyvibes - Contact Us">Contact Us</h1>
                <br />
                <p>Are You looking for a DJ for an upcoming event? (Let us know!)<br/>
                  <br />
                  Would you like to get involved with Pozzyvibes?<br />
                  <br />
                  We are always looking for Pozzyvibers around the world. Let us know if you are
                  interested in being a potential volunteer for any upcoming events, or if you are
                  interested in volunteering to be a content creator. We welcome guest writers and
                  artists who would like to contribute to our blog and efforts, and if you know anyone
                  who you think should be featured in the spotlight please let us know.<br />
                  <br />
                  Do you have something Pozzy you would like to contribute?<br />
                  <br />
                  Mail To:<br />
                  Pozzyvibes LLC,<br />
                  PO Box 290674<br />
                  Port Orange, FL 32129<br />
                  <br />
                  Email:<br />
                  info@pozzyvibes.com<br />
                  <br>
                  Call: <br />
                  (720)-600-2642 <br />
                  <br />
                  Follow us on social media!<br />
                </p>
                <a href="https://twitter.com/Pozzyvibes" class="icon fa-twitter" target="_blank">Twitter</a><br>
                <a href="https://www.facebook.com/Pozzyvibes/" class="icon fa-facebook" target="_blank">Facebook</a><br>
                <a href="https://www.instagram.com/pozzyvibes/" class="icon fa-instagram" target="_blank">Instagram</a><br>
              </div>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PozzyStory',
  data: () => ({
  }),
}
</script>
